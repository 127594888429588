import React from 'react';
import './HeroSection.css';
// import lipsIcon from '../images/Lips-Emoji-PNG-Pic.png';
// import eyeIcon from '../images/6502024cbfe9e66618625b5e56776702.png';

function HeroSection() {
  return (
    <div className="hero-container">
      <h1 className="company-name">Symphonic Labs</h1>
      <h2 className="company-subtitle">Building superpowers for ordinary people.</h2>
      <a 
        href="mailto:founders@symphoniclabs.com"
        className="contact-button"
      >
        Contact Us
      </a>
      {/* <div className="icons-container">
        <a 
          href="https://wavecamera.com" 
          className="icon-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="icon eyes-icon">
            <img src={eyeIcon} alt="Eye icon" className="eye-image" />
          </div>
        </a>
        <a 
          href="https://readtheirlips.com" 
          className="icon-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="icon mouth-icon">
            <img src={lipsIcon} alt="Lips icon" className="lips-image" />
          </div>
        </a>
      </div> */}
    </div>
  );
}

export default HeroSection;
